<template>
  <div id="page-placement-settings" class="relative">
    <iframe-v4 :src="url" height="100%" />
  </div>
</template>

<script>
import IframeV4 from '@/components/Core/IframeV4';

export default {
  components: { IframeV4 },
  computed: {
    url() {
      return (
        process.env.VUE_APP_V4_URL +
        `/v4/iframe/media-kit/${this.propertyId}/${this.variantId}`
      );
    },
    propertyId() {
      return this.$route.params.property_id;
    },
    variantId() {
      return this.$route.params.variant_id;
    }
  }
};
</script>
