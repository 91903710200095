<template>
  <div id="the-placement-header" class="flex border-bottom shadow-bottom">
    <div class="exit-button">
      <el-tooltip effect="light" content="Exit Digital Ad Manager">
        <a
          class="p-5 flex items-center justify-center border-right"
          @click="$router.go(-1)"
        >
          <icon :icon="exitIcon" />
        </a>
      </el-tooltip>
    </div>
    <div class="header-title px-5 py-3 flex-grow flex items-center font-bold">
      Digital Ad Placement Settings
    </div>
  </div>
</template>

<script>
import { cancel as exitIcon } from '@/vendor/icons';

export default {
  data() {
    return {
      //Icons
      exitIcon
    };
  }
};
</script>
